import * as React from "react";
import {
  Box,
  Button,
  Text,
  Stack,
  Flex,
  useBreakpointValue,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import IMGLineDataURI from "./assets/img/backgrounds/stroke-bg.svg";
import Partner from "./views/partners/Partners";
import Exchanges from "./views/partners/Exchanges";
import { mode } from "@chakra-ui/theme-tools";
import { useThemeColors } from "./hooks/useThemeColors";
const MainFontStyle = {
  fontSize: { base: "36px", md: "72px" },
  lineHeight: { base: "40px", md: "76px" },
  fontFamily: "Cal Sans",
  fontWeight: 600,
  letterSpacing: "2.88px",
};
const Business: React.FC = () => {
  const bottomValue = useBreakpointValue({ base: -11, md: -27 });
  const { colorMode } = useColorMode();
  const { color } = useThemeColors();
  return (
    <Box pt="100px">
      <Box color={color.text.primary} py={10} px={6} textAlign="center">
        <Text as="h1" {...MainFontStyle}>
          Build with NEOPIN
        </Text>
        <Box
          position="relative"
          display="inline-block"
          mt={{ base: "0px", md: 2 }}
        >
          <Text
            as="span"
            position="relative"
            display="inline-block"
            whiteSpace={{ base: "wrap", md: "nowrap" }}
            zIndex={1}
            {...MainFontStyle}
          >
            An{" "}
            <Box
              display={"inline-block"}
              position="relative"
              zIndex={0}
              _after={{
                content: '""',
                position: "absolute",
                display: "block",
                width: "100%",
                height: "100%",
                bottom: bottomValue,
                left: 0,
                zIndex: -1,
                bg: `url(${IMGLineDataURI}) no-repeat bottom`,
                backgroundSize: "contain",
                backgroundPosition: "bottom",
              }}
            >
              Open Web3 Platform{" "}
            </Box>
          </Text>
        </Box>

        <Text
          mt={"48px"}
          color={color.text.quinary}
          textStyle={{ base: "body14R", md: "body20R" }}
        >
          Expand the ecosystem and provide users with
          <br />
          easy and convenient Web3 environments.
        </Text>

        <Stack
          direction="row"
          spacing={4}
          mt={"72px"}
          justify="center"
          display={{ base: "none", md: "flex" }}
        >
          <Link
            href="https://docs.neopin.io/"
            padding="14px 18px"
            borderRadius="12px"
            size="lg"
            bg={color.button.quaternary.active}
            color={color.text.black}
            _hover={{ bg: "gray.300" }}
            textStyle={{ base: "button16M", md: "button16SB" }}
            isExternal={true}
          >
            NEOPIN Connect Docs &rarr;
          </Link>
          <Link
            href="mailto:support@neopin.io"
            padding="14px 18px"
            borderRadius="12px"
            size="lg"
            bg={color.button.primary.active}
            color={color.text.black}
            textStyle={{ base: "button16M", md: "button16SB" }}
            _hover={{ bg: "#4B4BCB" }}
          >
            Contact Us &rarr;
          </Link>
        </Stack>
      </Box>
      <Flex direction={"column"} gap={"80px"} alignItems={"center"}>
        <Partner />
        <Exchanges />
      </Flex>
    </Box>
  );
};

export default Business;
