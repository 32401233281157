export const removeDecimalZero = (toFixedStr: string) => {
  if (toFixedStr && toFixedStr.length > 0 && toFixedStr[0] === ".") {
    toFixedStr = "0" + toFixedStr;
  }
  const matchRegex = toFixedStr.match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/);
  if (!matchRegex || matchRegex.length < 1) {
    return "";
  }
  return matchRegex[1];
};
