import { Box, ResponsiveValue, useColorModeValue } from "@chakra-ui/react";

export const GradientBox = ({
  children,
  padding,
  ...props
}: {
  children: React.ReactNode;
  padding?: ResponsiveValue<string> | string;
}) => {
  const beforeGradient = useColorModeValue(
    " linear-gradient(146deg, #F2F7FA 8.28%, #FFF 54.06%)",
    "linear-gradient(153.28deg, #2c2e39 22.47%, #2c2e39 100%)"
  );
  const bgGradient = useColorModeValue(
    "linear-gradient(149.26deg, #88B8F7 0%, #fff 22.65%, #fff 98.5%)",
    "linear-gradient(149.26deg, #353a68 0%, #202127 22.65%, #1a1d33 98.5%)"
  );
  return (
    <Box
      position="relative"
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      bgGradient={bgGradient}
      borderRadius="14px"
      boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.1)"
      zIndex={1}
      {...props}
      _before={{
        bgGradient: beforeGradient,
        content: `""`,
        position: "absolute",
        borderRadius: "12px",
        width: "calc(100% - 4px)",
        height: "calc(100% - 4px)",
        zIndex: -1,
        left: "2px",
        top: "2px",
      }}
    >
      {children}
    </Box>
  );
};
