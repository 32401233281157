// src/components/StakingKeyValueSet.tsx
import { Box, Text, Tooltip, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";

interface StakingKeyValueSetProps {
  children: React.ReactNode;
  keyName: React.ReactNode;
  containerStyles?: React.CSSProperties;
  keyFontStyles?: React.CSSProperties;
  keyFontStylesDesktop?: React.CSSProperties;
  valueFontStyles?: React.CSSProperties;
  questionTooltip?: string;
}

const StakingKeyValueSet = ({
  children,
  containerStyles,
  keyName,
  keyFontStyles,
  keyFontStylesDesktop,
  valueFontStyles,
  questionTooltip,
}: StakingKeyValueSetProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const keyContainerRef = useRef<HTMLDivElement>(null);
  const valueContainerRef = useRef<HTMLDivElement>(null);
  const [fontReduce, setFontReduce] = useState<boolean>(false);

  const fontSize = useBreakpointValue({ base: "14px", md: "16px" });

  useEffect(() => {
    if (
      containerRef?.current &&
      keyContainerRef?.current &&
      valueContainerRef.current
    ) {
      if (
        containerRef.current.clientWidth <=
        keyContainerRef.current.clientWidth +
          valueContainerRef.current?.innerText.length * 10
      ) {
        setFontReduce(true);
      } else {
        setFontReduce(false);
      }
    }
  }, [children, valueContainerRef.current?.innerText.length]);

  return (
    <Box
      ref={containerRef}
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap="6px"
      style={containerStyles}
    >
      <Box
        ref={keyContainerRef}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Text
          as="p"
          display="inline"
          mr={questionTooltip ? "5px" : "0"}
          textStyle={{ base: "body14R", md: "body14R" }}
          color={"gray.500"}
        >
          {keyName}
        </Text>
        {/* {questionTooltip && (
          <QuestionIconTooltip textList={[questionTooltip]} />
        )} */}
      </Box>
      <Box
        ref={valueContainerRef}
        display="flex"
        flex="1"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        style={valueFontStyles}
        wordBreak="break-all"
        textStyle={{ base: "body14SB", md: "body14SB" }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default StakingKeyValueSet;
