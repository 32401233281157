import React from "react";
import { Box, Image } from "@chakra-ui/react";

interface IImageSize {
  odd?: {
    mobile?: number;
    tablet?: number;
    desktop?: number;
  };
  even?: {
    mobile?: number;
    tablet?: number;
    desktop?: number;
  };
}

interface ITokenIconProps {
  img?: string[]; // 이미지 주소
  size?: IImageSize; // 토큰 이미지 사이즈
  margin?: string; // 멀티플 토큰이면 겹쳐지는 영역 지정
}

// 멀티플 토큰 이미지 or 토큰 이미지
const TokenIcon: React.FC<ITokenIconProps> = ({ img, size, margin }) => {
  const defaultImage = "https://static.neopin.io/tokenimgv2/sb_dummy.png";

  if (img && img.length > 1) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        {img.map((image, index) => (
          <Image
            key={image}
            src={image}
            alt={`token-${index}`}
            borderRadius="full"
            boxSize={{
              base:
                index % 2 === 0
                  ? size?.odd?.mobile ?? "32px"
                  : size?.even?.mobile ?? "52px",
              md:
                index % 2 === 0
                  ? size?.odd?.tablet ?? "32px"
                  : size?.even?.tablet ?? "52px",
              lg:
                index % 2 === 0
                  ? size?.odd?.desktop ?? "32px"
                  : size?.even?.desktop ?? "52px",
            }}
            margin={index % 2 === 1 ? margin ?? "0px 0px 0px -6px" : undefined}
            zIndex={index % 2 === 1 ? 1 : undefined}
          />
        ))}
      </Box>
    );
  }

  return (
    <Image
      src={img && img[0] ? img[0] : defaultImage}
      alt="token"
      borderRadius="full"
      boxSize={{
        base: size?.even?.mobile ?? "52px",
        md: size?.even?.tablet ?? "52px",
        lg: size?.even?.desktop ?? "52px",
      }}
    />
  );
};

export default TokenIcon;
