// src/App.tsx
import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Box, Image, useColorMode, useColorModeValue } from "@chakra-ui/react";
import Header from "./components/Header";
import backgroundDarkImage from "./assets/img/backgrounds/circle-dark.png";
import backgroundLightImage from "./assets/img/backgrounds/circle-light.png";
import mobBackgroundLightImage from "./assets/img/backgrounds/circle-light-mob.png";
import mobBackgroundDarkImage from "./assets/img/backgrounds/circle-dark-mob.png";
import Home from "./Home";
import Footer from "./components/Footer";
import Business from "./Partners";
import RedirectToDefi from "./RedirectToDefi";
const BackgroundImage: React.FC = () => {
  const location = useLocation();
  const backgroundImage = useColorModeValue(
    backgroundLightImage,
    backgroundDarkImage
  );
  const mobBackgroundImage = useColorModeValue(
    mobBackgroundLightImage,
    mobBackgroundDarkImage
  );
  // 현재 경로가 '/'인지 확인
  const isHomePage = location.pathname === "/";

  return isHomePage ? (
    <Box
      zIndex={-1}
      position="absolute"
      h="100%"
      w="100%"
      minW={{ base: "100%", md: "1200px" }}
      overflow="hidden"
      top={"0px"}
    >
      <Box display={{ base: "block", md: "none" }}>
        <Image
          src={mobBackgroundImage}
          alt="bg-mobile"
          objectFit="cover"
          width="100%"
          height="100%"
        />
      </Box>
      <Box display={{ base: "none", md: "block" }}>
        <Image
          src={backgroundImage}
          alt="bg-desktop"
          objectFit="cover"
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  ) : null;
};

const App: React.FC = () => {
  return (
    <Router>
      <Box
        height={"100vh"}
        minHeight="100vh" // 최소 높이를 100vh로 설정하여 배경이 화면을 덮도록 함
      >
        <Header />
        <Box as="main">
          <BackgroundImage />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/partners" element={<Business />} />
            <Route path="/launch" element={<div>Launch App Page</div>} />
            {/** move to app.neopin.io */}
            <Route path="/pool/*" element={<RedirectToDefi />} />
            <Route path="/ai/*" element={<RedirectToDefi />} />
            <Route path="/swap" element={<RedirectToDefi />} />
            <Route path="/governance/*" element={<RedirectToDefi />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
};

export default App;
