export const APP_ENV = process.env.REACT_APP_ENV || ("local" as ENV_LIST);
export enum ENV_LIST {
  LOCAL = "local",
  DEV = "dev",
  DEV2 = "dev2",
  DQ = "dq",
  LIVE = "live",
}

// Neopin 앱 스토어 링크
export const GOOGLE_PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.blockchain.crypto.wallet.neopin";
export const APPLE_STORE_LINK =
  "https://apps.apple.com/kr/app/apple-store/id1600381072";

export const NEOPIN_DAPP_LINK = {
  [ENV_LIST.LOCAL]: "https://dev.neopin.io",
  [ENV_LIST.DEV]: "https://dev.neopin.io",
  [ENV_LIST.DEV2]: "https://dev2.neopin.io",
  [ENV_LIST.DQ]: "https://inapp.dq.neopin.pmang.cloud",
  [ENV_LIST.LIVE]: "https://app.neopin.io",
}[APP_ENV];

const apiEndpoints: Record<ENV_LIST, string> & { [key: string]: string } = {
  [ENV_LIST.LOCAL]: "https://api.dev.neopin.io/napi",
  [ENV_LIST.DEV]: "https://api.dev.neopin.io/napi",
  [ENV_LIST.DEV2]: "https://api2.dev.neopin.io/napi",
  [ENV_LIST.DQ]: "https://api.dq.neopin.io/napi",
  [ENV_LIST.LIVE]: "https://api.neopin.io/napi",
};

if (!apiEndpoints[APP_ENV]) {
  throw new Error(`Invalid APP_ENV value: ${APP_ENV}`);
}

export const API_URL = apiEndpoints[APP_ENV];

export const LANDING_PAGE_URL = {
  [ENV_LIST.LIVE]: "https://neopin.io",
  [ENV_LIST.DQ]: "https://dq.neopin.io",
  [ENV_LIST.DEV]: "https://dq.neopin.io",
  [ENV_LIST.DEV2]: "https://dq.neopin.io",
}[APP_ENV];
