import {
  Box,
  Button,
  Text,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";

export const GradientButton = ({
  children,
  onClick,
  link,
  color = "black",
}: {
  children: React.ReactNode;
  onClick?: () => void;
  color?: string;
  link?: string;
  isRightArrow?: boolean;
  isDisabled?: boolean;
}) => {
  const theme = useTheme();
  const textColor = useColorModeValue("#1A1D28", "#fff");
  return (
    <Box
      as={link ? "a" : undefined}
      href={link}
      target={link ? "_blank" : undefined}
      display="inline-block"
      position="relative"
      borderRadius="20px" // 둥근 테두리
      p="1px" // 그라데이션 보더 두께를 위해 1px 패딩
      background="transparent" // 그라데이션 보더
      cursor={"pointer"}
      onClick={link ? undefined : onClick}
      w={"fit-content"}
      _before={{
        content: '""',
        position: "absolute",
        top: "-1px",
        right: "-1px",
        bottom: "-1px",
        left: "-1px",
        zIndex: 2,
        borderRadius: "inherit",
        background: "linear-gradient(90deg, #2CC7FB, #B588FF)", // 그라데이션 보더,
        padding: "1px", // 내부 컨텐츠와의 간격
        mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        maskComposite: "exclude",
        WebkitMask:
          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "destination-out",
      }}
    >
      <Button
        display="inline-flex"
        alignItems="center"
        justifyContent="space-between"
        borderRadius="20px" // 둥근 테두리
        background="transparent" // 버튼 배경 투명
        color={color}
        border="1px solid transparent"
        _hover={{
          background: "rgba(255, 255, 255, 0.1)", // 호버 시 약간의 배경색 추가
        }}
        _active={{
          background: "rgba(255, 255, 255, 0.2)", // 클릭 시 약간 더 진한 배경색
        }}
        _focus={{
          boxShadow: "outline",
        }}
        height="auto"
        minW="120px"
        fontSize="16px"
        p={{ base: "6px 10px 4px 12px", md: "6px 16px 4px 20px" }}
      >
        <Text mr="8px" textStyle={{ base: "button14M", md: "button18M" }}>
          {children}
        </Text>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8.7391 13.0987L9.55678 14L15 8L9.55678 2L8.7391 2.90133L12.7864 7.36267L1 7.36267L1 8.63733L12.7864 8.63733L8.7391 13.0987Z"
            fill={textColor}
          />
        </svg>
      </Button>
    </Box>
  );
};
