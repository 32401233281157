export const EXCHANGES = [
  { name: "huobi" },
  { name: "gate" },
  { name: "bitget" },
  { name: "mexc" },
  { name: "bittrex" },
  { name: "bithumb" },
  { name: "coinone" },
  { name: "gopax" },
  { name: "kobit" },
  { name: "probit" },
  { name: "indodax" },
];
