import { useColorModeValue } from "@chakra-ui/react";

export const ColorWhiteNeopinIcon = ({ color = "white" }) => {
  const colorwhite = useColorModeValue("black", "white");
  return (
    <svg
      width="108"
      height="36"
      viewBox="0 0 108 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.255 17.3287C46.2332 17.3366 46.2082 17.3238 46.2033 17.3014C45.8595 15.7269 46.7909 13.5825 49.0335 12.8152C52.0364 11.7878 53.8552 13.8216 54.1596 14.4773C54.1689 14.4974 54.1581 14.5194 54.1371 14.5264C53.5845 14.7138 50.4817 15.8052 46.255 17.3287ZM50.2446 10.5017C47.6418 10.5532 45.4934 12.1537 44.6007 14.0506C43.5413 16.3015 44.2067 19.1862 45.387 20.7869C46.844 22.763 49.5222 24.229 52.9341 22.9978C55.7929 21.966 56.6066 19.5658 56.6807 19.3289C56.6841 19.3173 56.679 19.3072 56.6675 19.3031C56.5035 19.2432 55.3144 18.8084 54.6679 18.5655C54.6544 18.5604 54.6422 18.5669 54.639 18.5807C54.455 19.3328 53.2343 21.0734 51.1334 21.3103C48.5003 21.6076 47.3205 20.2455 46.9103 19.4009C46.9018 19.383 46.9111 19.3625 46.9299 19.3557C47.5385 19.1413 55.8462 16.2121 57.0524 15.7644C57.0664 15.7591 57.073 15.7458 57.0693 15.7315C56.6694 14.0796 54.9386 10.4088 50.2446 10.5017Z"
        fill={colorwhite}
        stroke={colorwhite}
        stroke-width="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M65.5446 21.2496C63.1592 21.2496 61.2255 19.3231 61.2255 16.9471C61.2255 14.5708 63.1592 12.6444 65.5446 12.6444C67.9301 12.6444 69.8638 14.5708 69.8638 16.9471C69.8638 19.3231 67.9301 21.2496 65.5446 21.2496ZM65.5446 10.4277C61.9302 10.4277 59 13.3466 59 16.9471C59 20.5476 61.9302 23.4662 65.5446 23.4662C69.1591 23.4662 72.089 20.5476 72.089 16.9471C72.089 13.3466 69.1591 10.4277 65.5446 10.4277Z"
        fill={colorwhite}
        stroke={colorwhite}
        stroke-width="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M89.5378 23.1044H91.6749C91.6871 23.1044 91.6971 23.0937 91.6971 23.0806V11.5385C91.6971 11.5254 91.6871 11.5146 91.6749 11.5146H89.5378C89.5256 11.5146 89.5156 11.5254 89.5156 11.5385V23.0806C89.5156 23.0937 89.5256 23.1044 89.5378 23.1044Z"
        fill={colorwhite}
        stroke={colorwhite}
        stroke-width="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M89.5378 9.70336H91.6749C91.6871 9.70336 91.6971 9.69391 91.6971 9.68237V7.55126C91.6971 7.53972 91.6871 7.53027 91.6749 7.53027H89.5378C89.5256 7.53027 89.5156 7.53972 89.5156 7.55126V9.68237C89.5156 9.69391 89.5256 9.70336 89.5378 9.70336Z"
        fill={colorwhite}
        stroke={colorwhite}
        stroke-width="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106.07 12.9243C105.025 11.4526 103.351 10.438 101.269 10.438C99.003 10.438 97.7395 11.2464 96.9941 12.0731C96.9723 12.0971 96.9334 12.0819 96.9334 12.0497V10.5277C96.9334 10.4725 96.8886 10.4277 96.8334 10.4277H94.7094C94.6541 10.4277 94.6094 10.4725 94.6094 10.5277V23.0795C94.6094 23.093 94.6205 23.104 94.6342 23.104H96.953C96.9669 23.104 96.9778 23.093 96.9778 23.0795V17.5537C96.98 17.443 96.981 17.3258 96.981 17.2006C96.981 15.4215 97.8783 12.5433 101.35 12.6989C104.603 12.8448 105.005 16.037 104.915 17.3995V23.0633C104.915 23.077 104.926 23.0878 104.939 23.0878H107.31C107.324 23.0878 107.335 23.077 107.335 23.0633V17.3698C107.335 15.9611 107.114 14.3863 106.07 12.9243Z"
        fill={colorwhite}
        stroke={colorwhite}
        stroke-width="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.9335 21.0517C78.6538 21.0517 76.8059 19.2257 76.8059 16.9734C76.8059 14.7209 78.6538 12.895 80.9335 12.895C83.2132 12.895 85.0612 14.7209 85.0612 16.9734C85.0612 19.2257 83.2132 21.0517 80.9335 21.0517ZM80.9335 10.7998C79.2957 10.7998 77.8062 11.4211 76.6912 12.4358C76.6682 12.4566 76.6318 12.4411 76.6318 12.4103V11.2588C76.6318 11.2456 76.6209 11.2349 76.6076 11.2349H74.4227C74.4093 11.2349 74.3984 11.2456 74.3984 11.2588V26.7759C74.3984 26.789 74.4093 26.7998 74.4227 26.7998H76.7103C76.7237 26.7998 76.7346 26.789 76.7346 26.7759V21.6046C76.7346 21.5731 76.7717 21.5568 76.7957 21.5779C77.8983 22.5367 79.3468 23.1187 80.9335 23.1187C84.3876 23.1187 87.1876 20.3611 87.1876 16.9593C87.1876 13.5574 84.3876 10.7998 80.9335 10.7998Z"
        fill={colorwhite}
        stroke={colorwhite}
        stroke-width="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.191 10.438C33.9289 10.438 32.6656 11.2434 31.9198 12.0687C31.8967 12.0942 31.8551 12.078 31.8551 12.0436V10.4523C31.8551 10.4385 31.8439 10.4277 31.8303 10.4277H29.556C29.5422 10.4277 29.5312 10.4385 29.5312 10.4523V23.0795C29.5312 23.093 29.5422 23.104 29.556 23.104H31.8747C31.8885 23.104 31.8995 23.093 31.8995 23.0795V17.5701C31.9017 17.4545 31.9027 17.332 31.9027 17.2006C31.9027 15.4212 32.8003 12.5433 36.2714 12.6989C39.5253 12.8448 39.9269 16.037 39.8364 17.3995V23.0633C39.8364 23.0768 39.8476 23.0878 39.8612 23.0878H42.2319C42.2458 23.0878 42.2567 23.0768 42.2567 23.0633V17.3695C42.2567 15.9611 42.0358 14.386 40.9914 12.9243C39.947 11.4524 38.2731 10.438 36.191 10.438Z"
        fill={colorwhite}
        stroke={colorwhite}
        stroke-width="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9981 15.4055C20.9057 11.4171 17.5372 8.24226 13.4739 8.31419C12.3077 8.33493 11.2116 8.62091 10.2428 9.1123C9.90289 7.82626 9.08926 6.3822 7.31061 6.05922C5.22128 5.67987 3.95312 7.18584 4.08358 8.78275C4.21231 10.3544 5.51034 10.8748 5.82995 13.5427C6.15197 16.2306 4.84811 20.4421 0 25.8539L3.88446 29C3.88446 29 8.53929 24.2898 10.2136 18.1111C10.4872 17.1017 10.5425 16.6428 10.8871 15.1137C11.2318 13.5845 12.42 12.5852 13.9054 12.7183C15.4465 12.8563 16.5849 14.2263 16.4479 15.7786C16.311 17.3309 14.9511 18.4721 13.4097 18.3396C12.8927 18.2954 12.3908 18.0789 12.2291 18.0001C11.9428 19.386 11.4491 20.891 10.9362 22.1123C11.8092 22.5754 12.9044 22.7735 13.8076 22.7573C17.8713 22.6853 21.0904 19.3936 20.9981 15.4055Z"
        fill={colorwhite}
      />
    </svg>
  );
};
