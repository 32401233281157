// src/typography.ts

export const fontFamily = {
  // roboto: `"Roboto", "Noto Sans", sans-serif`,
  // notoSans: `"Apple SD Gothic Neo", "Noto Sans CJK KR", "Noto Sans", sans-serif`,
  roboto: `"Pretendard Variable", "Apple SD Gothic Neo", sans-serif`,
  notoSans: `"Cal Sans", "Apple SD Gothic Neo", sans-serif`,
  pretendard: `"Pretendard Variable", "Apple SD Gothic Neo", sans-serif`,
  calSans: `"Cal Sans", "Apple SD Gothic Neo", sans-serif`,
  appleSDGothicNeo: `"Apple SD Gothic Neo"`,
};

export const textStyles = {
  display1: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 800,
    fontSize: "46px",
    lineHeight: "60px",
    letterSpacing: "-0.5px",
  },
  display2: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 800,
    fontSize: "36px",
    lineHeight: "44px",
    letterSpacing: "-0.25px",
  },
  display3: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "34px",
    letterSpacing: "-0.25px",
  },
  display32R: {
    fontFamily: fontFamily.pretendard,
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "40px" /* 125% */,
  },
  display48: {
    fontFamily: fontFamily.pretendard,
    fontSize: "48px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "64px" /* 125% */,
  },
  h1: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0px",
  },
  h2: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "0px",
  },
  h3: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "26px",
    letterSpacing: "0px",
  },
  title1: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0px",
  },
  title2: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0px",
  },
  title3: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0px",
  },
  body1: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0px",
  },
  body2: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "30px",
    letterSpacing: "0px",
  },
  body3: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "26px",
    letterSpacing: "0px",
  },
  body4: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
  },
  body5: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
  },
  body6: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.5px",
  },
  body7: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.5px",
  },
  body8: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.5px",
  },
  label1: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.5px",
  },
  label2: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.5px",
  },
  label3: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "20px",
    letterSpacing: "0.5px",
  },
  label4: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.75px",
  },
  label5: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "1px",
  },
  caption1: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
  },
  caption2: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
  },
  caption3: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.5px",
  },
  caption4: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.5px",
  },
  caption5: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    letterSpacing: "0.5px",
  },
  caption11R: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "14px",
    letterSpacing: "0.055px",
  },
  caption12R: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.06px",
  },
  caption12M: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.06px",
  },
  caption13R: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "18px",
    letterSpacing: "0.033px",
  },
  caption13M: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.005em",
  },
  caption20R: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "24px",
  },
  button12M: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.12px",
  },
  button14M: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.105px",
  },
  button14SB: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.105px",
  },
  button15SB: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "20px",
    letterSpacing: "0.075px",
  },
  button16M: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.08px",
  },
  button16SB: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.005em",
  },
  button18M: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.005em",
  },
  body14SB: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "22px",
  },
  body14R: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
  },
  body14M: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
  },
  body16SB: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
  },
  body16M: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
  },
  body16R: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  body18R: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26px",
  },
  body20R: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
  },
  title16SB: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
  },
  title18SB: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
  },
  title20SB: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
  },
  title24B: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  title28SB: {
    fontFamily: fontFamily.pretendard,
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "36px",
  },
  // 스테이크 상세페이지 토큰 제목(모바일)
  title20SBC: {
    fontFamily: "CalSans",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "28px",
  },
  // 스테이크 상세페이지 토큰 제목(PC)
  title32SBC: {
    fontFamily: "CalSans",
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "40px",
  },
};
