import { Box, Image } from "@chakra-ui/react";

interface IPartnerButton {
  index: number;
  initialDisplayCount: number;
  logo: { name: string };
}
export const PartnerButton = ({
  index,
  initialDisplayCount,
  logo,
}: IPartnerButton) => {
  return (
    <Box
      key={index + initialDisplayCount}
      textAlign="center"
      borderRadius={"10px"}
      border={"1px solid"}
      borderColor={"gray.800"}
      maxH={"80px"}
      p={"14px 18px"}
      boxSize={"border-box"}
    >
      <Image
        src={require(`../../assets/img/logos/${logo.name}.png`)}
        alt={`${logo.name} Logo`}
        boxSize="100%"
        objectFit="contain"
        mb={2}
      />
    </Box>
  );
};
