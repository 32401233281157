// styles.ts
import { mode } from "@chakra-ui/theme-tools";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";
export const styles = {
  global: (props: StyleFunctionProps) => ({
    body: {
      bg: mode("white", "neutral.1200")(props),
      color: mode("black", "white")(props),
    },
    html: {
      height: "100%",
    },
    "#root": {
      height: "100%",
    },
  }),
};
