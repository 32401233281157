import {
  Box,
  Button,
  Collapse,
  Flex,
  SimpleGrid,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { PARTNERS } from "../../config/partners";
import { useState } from "react";
import { PartnerButton } from "../../components/partners/PartnerButton";
import { mode } from "@chakra-ui/theme-tools";
import { useThemeColors } from "../../hooks/useThemeColors";
const Partners: React.FC = () => {
  const [showMore, setShowMore] = useState(false);
  const initialDisplayCount = 18; // Initially display 18 items

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };
  const { colorMode } = useColorMode();

  const gradient = mode(
    "linear(to-t, white, rgba(255, 255, 255, 0))",
    "linear(to-t, #1A1D28, rgba(26, 29, 40, 0))"
  )({ colorMode });

  return (
    <Flex direction="column" alignItems="center" gap="40px" mt="150px">
      <Text
        fontSize={{ base: "24px", md: "36px" }}
        fontFamily={"Cal Sans"}
        fontWeight={600}
        lineHeight={{ base: "28px", md: "40px" }}
        letterSpacing={{ base: "0.96px", md: "1.44px" }}
      >
        Partner
      </Text>
      <Box p={5} w={{ base: "100%", lg: "1440px" }} m="0 auto">
        <SimpleGrid columns={{ base: 2, sm: 3, md: 4, lg: 6 }} spacing="12px">
          {PARTNERS.slice(
            0,
            showMore ? PARTNERS.length : initialDisplayCount
          ).map((logo, index) => (
            <PartnerButton
              key={index}
              index={index}
              logo={logo}
              initialDisplayCount={initialDisplayCount}
            />
          ))}
        </SimpleGrid>

        {!showMore && (
          <Box
            position="relative"
            textAlign="center"
            mt={4}
            height="100px" // Height of the gradient overlay
            zIndex={1}
            display={"flex"}
            justifyContent={"center"}
          >
            <Box
              position="absolute"
              bottom="110px"
              left={0}
              right={0}
              height="100%" // Full height for the gradient
              bgGradient={gradient}
              borderRadius="10px"
            />
            <Button
              onClick={toggleShowMore}
              size="sm"
              position="relative"
              zIndex={2}
              backgroundColor={"transparent"}
              display={"flex"}
              gap={"8px"}
              alignItems={"center"}
              _hover={{ backgroundColor: "transparent" }}
            >
              <Text textStyle={"button16M"} color={"gray.400"}>
                More
              </Text>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path d="M12.5 6L8.5 10L4.5 6" stroke="#98A2A8" />
              </svg>
            </Button>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default Partners;
