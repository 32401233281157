// src/layer.ts

export const layerStyles = {
  //   h1: {
  //     fontFamily: "Cal Sans",
  //     fontSize: "112px",
  //     fontStyle: "normal",
  //     fontWeight: "600",
  //     lineHeight: "116px",
  //     letterSpacing: "4.48px",
  //     textTransform: "uppercase",
  //   },
};
