import * as React from "react";
import { Tooltip, Icon } from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";

interface QuestionTooltipProps {
  label: string;
}

const QuestionTooltip: React.FC<QuestionTooltipProps> = ({ label }) => {
  const labelLines = label.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
  return (
    <Tooltip
      label={labelLines}
      fontSize="12px"
      bg="rgba(0, 0, 0, 0.7)"
      color="white"
      borderRadius="md"
      p={2}
      hasArrow
      placement="bottom"
      fontWeight="400"
      lineHeight="1.3"
    >
      <Icon as={QuestionIcon} color="rgba(111, 121, 128, 1)" boxSize={"12px"} />
    </Tooltip>
  );
};

export default QuestionTooltip;
