import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { EXCHANGES } from "../../config/exchanges";
import { PartnerButton } from "../../components/partners/PartnerButton";

const Exchanges = () => {
  return (
    <Flex
      direction={"column"}
      gap={"40px"}
      mt={"150px"}
      w={{ base: "100%", lg: "1440px" }}
      m="0 auto"
      alignItems={"center"}
    >
      <Text
        fontSize={{ base: "24px", md: "36px" }}
        fontFamily={"Cal Sans"}
        fontWeight={600}
        lineHeight={{ base: "28px", md: "40px" }}
        letterSpacing={{ base: "0.96px", md: "1.44px" }}
      >
        Exchanges
      </Text>
      <Box p={5}>
        <SimpleGrid columns={{ base: 2, sm: 3, md: 4, lg: 6 }} spacing="12px">
          {EXCHANGES.map((logo, index) => {
            return (
              <PartnerButton
                index={index}
                initialDisplayCount={12}
                logo={logo}
              />
            );
          })}
        </SimpleGrid>
      </Box>
    </Flex>
  );
};
export default Exchanges;
