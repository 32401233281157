import * as React from "react";
import { Tooltip, Icon } from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";

interface QuestionTooltipProps {
  children: React.ReactNode;
  label: string;
}

const TooltipWrapper: React.FC<QuestionTooltipProps> = ({
  children,
  label,
}) => {
  return (
    <Tooltip
      label={label}
      fontSize="12px"
      bg="rgba(0, 0, 0, 0.7)"
      color="white"
      borderRadius="md"
      p={2}
      hasArrow
      placement="bottom"
      fontWeight="400"
      lineHeight="1.3"
    >
      {children}
    </Tooltip>
  );
};

export default TooltipWrapper;
