export const colors = {
  blue: {
    50: "#EAF9FF",
    100: "#BAEEFF",
    200: "#88E2FF",
    300: "#5CD6FF",
    400: "#2CC7FB",
    500: "#1AA9D9",
    600: "#0C8CB7",
    700: "#017095",
    800: "#005673",
    900: "#003D51",
  },
  purple: {
    50: "#F1F0FF",
    100: "#C9C5FF",
    200: "#A29AFF",
    300: "#7A6EFF",
    400: "#554AE2",
    500: "#5248C5",
    600: "#413BA7",
    700: "#322A8A",
    800: "#241E6D",
    900: "#181350",
  },
  gray: {
    0: "#FFFFFF",
    50: "#F2F7FA",
    100: "#DBE1E6",
    200: "#C4CCD1",
    300: "#AEB7BD",
    400: "#98A2A8",
    500: "#838E94",
    600: "#6F7980",
    700: "#5C656B",
    800: "#495257",
    900: "#373E42",
  },
  neutral: {
    50: "#DDE3FB",
    100: "#C5CCE9",
    200: "#B0B8D7",
    300: "#A3ABC9",
    400: "#979FBF",
    500: "#878EAA",
    600: "#717985",
    700: "#5D6584",
    800: "#4F5670",
    900: "#42485C",
    1000: "#303443",
    1100: "#20232F",
    1200: "#1A1D28",
  },
  green: {
    50: "#EFFFF2",
    100: "#CAFFD4",
    200: "#A6FFB6",
    300: "#81FF98",
    400: "#5DFF7B",
    500: "#47E164",
    600: "#33BF4D",
    700: "#239D39",
    800: "#157B28",
    900: "#0B5919",
  },
  yellow: {
    50: "#FFF9EC",
    100: "#FFEEC3",
    200: "#FFE29A",
    300: "#FFD670",
    400: "#FFCA47",
    500: "#DDAC33",
    600: "#BB8F22",
    700: "#997315",
    800: "#77580A",
    900: "#553E03",
  },
  red: {
    50: "#FFECEF",
    100: "#FFC2CC",
    200: "#FF99A8",
    300: "#FF6F85",
    400: "#FF4561",
    500: "#DD314B",
    600: "#BB2038",
    700: "#991327",
    800: "#77091A",
    900: "#55020F",
  },
};
