// src/hooks/useFetch.ts
import useSWR from "swr";

const fetcher = async (url: string) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("An error occurred while fetching the data.");
  }
  return response.json();
};

const useFetch = <T>(url: string) => {
  const { data, error, isLoading } = useSWR<T>(url, fetcher);

  return {
    data,
    error,
    isLoading,
  };
};

export default useFetch;
