import { useEffect } from "react";
import { NEOPIN_DAPP_LINK } from "./config/constants/common";

const RedirectToDefi = () => {
  useEffect(() => {
    const path = window.location.pathname;
    const search = window.location.search;
    window.location.href = `${NEOPIN_DAPP_LINK}${path}${search}`;
  });
  return null;
};

export default RedirectToDefi;
