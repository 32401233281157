// components.ts
import { mode } from "@chakra-ui/theme-tools";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";
import { fontFamily } from "./typography";

export const components = {
  Button: {
    baseStyle: {
      fontFamily: fontFamily.pretendard,
      fontWeight: 600,
      textAlign: "center",
      letterSpacing: "0.075px",
    },
    variants: {
      neopin: (props: StyleFunctionProps) => ({
        bg: mode("blue.400", "blue.600")(props),
        color: mode("white", "neutral.1200")(props),
        borderRadius: "22px",
        padding: "12px 20px",
        fontSize: "15px",
        lineHeight: "20px",
        _hover: {
          bg: mode("blue.500", "blue.700")(props),
        },
        _active: {
          bg: mode("blue.600", "blue.800")(props),
        },
      }),
    },
  },
};
