// src/theme.ts
import { extendTheme, ThemeConfig, ThemeOverride } from "@chakra-ui/react";
import { fontFamily, textStyles } from "./typography"; // 기존 텍스트 스타일
import { layerStyles } from "./layer"; // 레이어 스타일 임포트
import { styles } from "./styles";
import { colors } from "./colors";
import { components } from "./components";
const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const overrides: ThemeOverride = {
  colors,
  fonts: {
    heading: `'Cal Sans', sans-serif`,
    body: `'Pretendard', sans-serif`,
  },
  components,
  styles,
  textStyles,
  layerStyles,
};

const theme = extendTheme(overrides, config);

export default theme;
